import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import 'element-plus/theme-chalk/display.css'

import 'animate.css'
import 'wowjs/css/libs/animate.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


import Axios from './untils/axios';
import Util from './untils/util';


app.use(store).use(router).use(ElementPlus).use(Axios).use(Util).mount("#app");
