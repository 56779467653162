import { createStore } from "vuex";

export default createStore({
  state: {
    common: {
      server: {}
    }
  },
  getters: {},
  mutations: {
    setCommon(state, data) {
			state.common = data
		}
  },
  actions: {},
  modules: {},
});
