//下面是vue3必须加的，vue2不需要，只需要暴露出去get，post方法就可以
const $HttpImg = (url) => {
    if (!url) return
    if (url.indexOf('http') > -1) {
        return url
    } else if (url.indexOf('base64') > -1) {
        return url
    } else {
        return 'https://www.yfez.cn' + url
    }
}

const $formatDate = (date) => {
    date = new Date(date)
    var YY = date.getFullYear() + '-'
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    // var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    // var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    // var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return YY + MM + DD
  }
  
  const $formatDate2 = (date) => {
    date = new Date(date)
    var YY = date.getFullYear() + '-'
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
    var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return YY + MM + DD + ' ' + hh + ':' + mm + ':' + ss
  }

export default {
    install: (app) => {
        app.config.globalProperties['$HttpImg'] = $HttpImg;
        app.config.globalProperties['$formatDate'] = $formatDate;
        app.config.globalProperties['$formatDate2'] = $formatDate2;
    }
}