<template>
  <!-- <HeaderNav /> -->
  <router-view />
  <!-- <Footer /> -->
</template>

<script>
// import HeaderNav from './components/HeaderNav/HeaderNav.vue'
// import Footer from './components/Footer/Footer.vue'

export default {
  name: 'App',
  components: {
    // HeaderNav,
    // Footer
  },
  created() {
    this.$get('index/common', {}).then(res => {
      this.$store.commit('setCommon', res.data)
    })
  }
}
</script>

<style lang="scss" scoped>
@import url('@/assets/css/index.scss');
</style>
